import {BrowserRouter} from 'react-router-dom';

// routes
import AllRoutes from '.';
import Footer4 from "../components/footer/Footer4";
import Navbar5 from "../components/navbars/Navbar5";

const Routes = () => {
  return (
    <>
      <BrowserRouter>
        <Navbar5/>
        <AllRoutes/>
        <Footer4/>
      </BrowserRouter>
    </>

  );
};

export default Routes;
