import {Link} from 'react-router-dom';
import {Col, Container, Row} from 'react-bootstrap';

// images
import logo from '../../assets/images/logo.png';
import logo2 from '../../assets/images/Web_logo_small.png';

import icon1 from '../../assets/images/icons/vector.png';

import icon5 from '../../assets/images/icons/vector4.png';


import BackToTop from "../BackToTop";

const Footer4 = () => {
  return (
    <>
      <BackToTop/>
      <section className="py-3 bg-prim position-relative">
        <Container className="my-4" fluid={true}>
          <Link className="navbar-brand me-lg-4 mb-4 me-auto d-flex align-items-center pt-0" to="#">
            <img src={logo} height="50" alt=""/>
          </Link>
          <Row>
            <Col xl={6}>
              <p className="text-muted">
                Create a personalized and meaningful conversation to boost consumer engagement on any available message
                channels.
              </p>
            </Col>
            <Col xl="auto">
              <div className="mt-md-0 mt-4">
                <ul className="list-unstyled mb-0">

                  <li className="d-inline-block me-3">
                    <a href="https://www.facebook.com/peacom.co">
                      <img src={icon1} alt='fb'/>
                    </a>
                  </li>

                  <li className="d-inline-block me-3">
                    <a href="https://www.linkedin.com/company/peacom-co/">
                      <img src={icon5} alt='icon'/>
                    </a>
                  </li>

                </ul>
              </div>
            </Col>
          </Row>
          <hr/>
          <Row>
            <Col xl={6}>
              <div>
                <h6 className="mb-3 mt-3 mt-sm-2 fs-14 fw-semibold text-uppercase">Our locations</h6>
                <ul className="list-unstyled">
                  <li>Da Nang city
                  </li>
                  <li>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                         className="bi bi-house-door-fill me-2" viewBox="0 0 16 16">
                      <path
                        d="M6.5 14.5v-3.505c0-.245.25-.495.5-.495h2c.25 0 .5.25.5.5v3.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5Z"/>
                    </svg>
                    06 Nguyễn Duy Hiệu, Phường An Hải Đông, Quận Sơn Trà, Thành Phố Đà Nẵng, Việt Nam (553920)
                  </li>
                  <li>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                         className="bi bi-house-door-fill me-2" viewBox="0 0 16 16">
                      <path
                        d="M6.5 14.5v-3.505c0-.245.25-.495.5-.495h2c.25 0 .5.25.5.5v3.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5Z"/>
                    </svg>
                    3th FL, 111 Le Thanh Nghi, Hoa  Bac Ward, Hai Chau District, Da Nang City
                  </li>
                  <li>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                         className="bi bi-telephone-fill me-2" viewBox="0 0 16 16">
                      <path fillRule="evenodd"
                            d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
                    </svg>
                    +84 90 951 4093
                  </li>
                  <li>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                         className="bi bi-envelope-fill me-2" viewBox="0 0 16 16">
                      <path
                        d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z"/>
                    </svg>
                    contact@peacom.co
                  </li>



                </ul>
                <ul className="list-unstyled">
                  <li>Ho Chi Minh city
                  </li>
                  <li>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                         className="bi bi-house-door-fill me-2" viewBox="0 0 16 16">
                      <path
                          d="M6.5 14.5v-3.505c0-.245.25-.495.5-.495h2c.25 0 .5.25.5.5v3.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5Z"/>
                    </svg>
                    P.903A, 9th FL, Diamond Plaza, 34 Le Duan street, District 1
                  </li>
                </ul>
                <ul className="list-unstyled">
                  <li>Singapore
                  </li>
                  <li>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                         className="bi bi-link-45deg" viewBox="0 0 16 16">
                      <path
                          d="M4.715 6.542 3.343 7.914a3 3 0 1 0 4.243 4.243l1.828-1.829A3 3 0 0 0 8.586 5.5L8 6.086a1.002 1.002 0 0 0-.154.199 2 2 0 0 1 .861 3.337L6.88 11.45a2 2 0 1 1-2.83-2.83l.793-.792a4.018 4.018 0 0 1-.128-1.287z"/>
                      <path
                          d="M6.586 4.672A3 3 0 0 0 7.414 9.5l.775-.776a2 2 0 0 1-.896-3.346L9.12 3.55a2 2 0 1 1 2.83 2.83l-.793.792c.112.42.155.855.128 1.287l1.372-1.372a3 3 0 1 0-4.243-4.243L6.586 4.672z"/>
                    </svg>
                    <a href="https://peacom.co">&nbsp;https://peacom.sg</a>
                  </li>
                  <li>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                         className="bi bi-house-door-fill me-2" viewBox="0 0 16 16">
                      <path
                          d="M6.5 14.5v-3.505c0-.245.25-.495.5-.495h2c.25 0 .5.25.5.5v3.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5Z"/>
                    </svg>
                    2 ANG MO KIO DRIVE #D2-01D, ITE COLLEGE CENTRAL, SINGAPORE (567720)
                  </li>
                </ul>
              </div>
            </Col>
            <Col xl={2} sm={4} xs={6}>
              <div>
                <h6 className="mb-3 mt-3 mt-sm-2 fs-14 fw-semibold text-uppercase">About</h6>
                <ul className="list-unstyled">
                  <li>
                    <Link to="/home">
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link to="/about-us">
                      About us
                    </Link>
                  </li>
                  <li>
                    <Link to="/service">
                      Service
                    </Link>
                  </li>
                  {/*<li>*/}
                  {/*  <Link to="#">*/}
                  {/*    Solutions*/}
                  {/*  </Link>*/}
                  {/*</li>*/}
                </ul>
              </div>
            </Col>
            <Col xl={2} sm={4} xs={6}>
              <div>
                <h6 className="mb-3 mt-3 mt-sm-2 fs-14 fw-semibold text-uppercase">Information</h6>
                <ul className="list-unstyled">
                  <li>
                    <Link to="/contact">
                      Contacts
                    </Link>
                  </li>
                  <li>
                    <Link to="/blog-view">
                      Blog
                    </Link>
                  </li>
                </ul>
              </div>
            </Col>

            <Col xl={2} sm={4} xs={6}>
              <div>
                <h6 className="mb-3 mt-3 mt-sm-2 fs-14 fw-semibold text-uppercase">Legal</h6>
                <ul className="list-unstyled">
                  <li>
                    <Link to="/privacy-policy">
                      Privacy Policy
                    </Link>
                  </li>
                  <li>
                    <li>
                      <Link to="/terms-service">
                        Terms of Service
                      </Link>
                    </li>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <div className=" text-center bg-prim2">
        <p className="px-2 mb-0 text-muted">
          <img className="me-3" src={logo2} alt='logo'/>
          2022 © <a href="https://peacom.co/">PeaCom.</a>

        </p>
      </div>
    </>


  );
};

export default Footer4;
