import Navbar1 from "./Navbar1";

const Navbar5 = () => {
  return(
      <div className="header-7">
          <Navbar1
              navClass="bg-white navbar-light zindex-10"
              hideSearch
              isSticky
              fixedWidth
              buttonClass="btn-outline-primary btn-sm"
          />
      </div>
  )
}
export default Navbar5;
